import React from "react";

export default function Disclosures() {
  return (
    <div className="lg:w-9/12 p-10">
      <h1 className="text-navyblue text-6xl font-semibold">Disclosures</h1>
      <br />
      <p>
        Sunfish Solutions, LLC ("Sunfish") may receive a referral fee for any
        loans booked through the affiliates mentioned in this advertisement.
      </p>
      <p>
        Sunfish works with a number of partner lenders and affiliates. Each
        affiliate has its own individual disclosures available on their website
        should you pursue a loan with them.
      </p>
      <p>
        Contact your legal, tax and financial advisors for advice on deciding
        whether this is the right product for you. Terms and conditions apply.
      </p>
      <br />
      <h2 className="font-navyblue text-lg font-semibold">
        General Site Disclosures
      </h2>
      <p className="my-3">
        Sunfish reserves the right to share user information with potential
        lenders, pharmacies, agencies, and medical providers. Sunfish reserves
        the right to utilize your information to support you in determining if
        you may qualify for a discount or Compassionate Care. Sunfish's website
        and mobile application may share information with other third party
        providers such as Google Analytics. Users may opt out of this
        information sharing by contacting info@joinsunfish.com or calling us at
        (510) 775-0064.
      </p>
      <p className="my-3">
        The offerings in this website are for Sunfish members and can only be
        distributed to potential Sunfish members by companies that have a signed
        contract or Memorandum of Understanding in pace to distribute this
        information. Due to the highly regulated nature of our business, any
        company distributing this information without Sunfish's explicit
        permission could receive a fine of up to $50,000. If you wish to become
        a partner with Sunfish, please contact us at info@joinsunfish.com.
      </p>
      <h2 className="font-navyblue text-lg font-semibold">
        Partner Lender Disclosures
      </h2>
      <p className="my-3">
        The offers that may appear are from companies which our partners,
        including MoneyLion, and its partners receive compensation. This
        compensation may influence the selection, appearance, and order of
        appearance of the offers listed below. However, this compensation also
        facilitates the provision by MoneyLion of certain services to you at no
        charge. The offers displayed in your results do not include all
        financial services companies or all of their available product and
        service offerings.
      </p>
      <p className="my-3">
        Minimum loan requirements might be higher than $5,000 in specific states
        due to legal requirements, and interest-rate caps may be lower in some
        states due to legal requirements and may impact your eligibility to
        qualify for a loan.
      </p>
      <p className="my-3">
        Loans for amounts over $20,000 may require additional underwriting
        review time.
      </p>
      <p className="my-3">
        Please visit individual lenders for up to date rates, terms, and
        additional disclosures. Some initial offers may reflect discounts such
        as autopay discount. Lowest rates reserved for the most creditworthy
        borrowers.
      </p>
      <p className="my-3">
        Sunfish collaborates with partner lenders and medical providers and may
        receive compensation. Please note that applying via our partner lenders
        does not mean you are qualified, and that not all loans are available in
        all regions. If you are ready to complete our Sunfish Form, this will
        not impact your credit score, but a hard credit pull will occur if you
        accept an offer via our partner lenders.
      </p>
      <h2 className="font-navyblue text-lg font-semibold">
        First Republic Bank Disclosures
      </h2>
      <p className="flex my-3">
        Banking products and services are provided by First Republic Bank,
        Member FDIC and Equal Housing Lender.&nbsp;
        <img
          src="/assets/frb_house.png"
          alt="First Republic Bank - House Icon"
          width="24"
          className="mb-2"
        ></img>
      </p>
      <p className="my-3">
        1 - Personal Line of Credit is an unsecured consumer loan that consists
        of a two-year, interest-only, revolving draw period followed by a fully
        amortizing repayment period of the remainder of the term. Draws are not
        permitted during the repayment period.
      </p>
      <p className="my-3">
        This product can only be used for personal, family or household
        purposes. It cannot be used for the following (among other
        prohibitions): to refinance or pay any First Republic loans or lines of
        credit, to purchase securities or investment products (including margin
        stock and cryptocurrency), for speculative purposes, for business or
        commercial uses, for a down payment on any property or for the direct
        payment of post-secondary educational expenses. This product cannot be
        used to pay off credit card debt at origination. Please note only debts
        that appear on your consumer credit report or student loans are eligible
        to be repaid at origination.
      </p>
      <p className="my-3">
        The terms of this product may differ from terms of your current loan(s)
        that are being paid off, including but not limited to student loans. By
        repaying such loans, you may permanently be giving up tax and repayment
        benefits, including forbearance, deferment and forgiveness, and you may
        not be able to reobtain such benefits if this loan is refinanced with
        another lender in the future.
      </p>
      <p className="my-3">
        Borrower must open a First Republic ATM Rebate Checking account
        (“Account”). Terms and conditions apply to the Account.
      </p>
      <p className="my-3">
        2 - Annual Percentage Rate. Rates effective as of 3/21/2023 and are
        subject to change.
      </p>
      <p className="my-3">
        If the Account is closed, the rate will increase by 5.00%. Rates shown
        include relationship-based pricing adjustments of: a) 2.00% for
        maintaining automatic payments and direct deposit with the Account, b)
        an additional 0.50% for depositing and maintaining a deposit balance of
        at least 10% of the approved loan amount into the Account, and c) an
        additional 0.25% for depositing and maintaining a deposit balance of at
        least 20% of the approved loan amount into the Account, with the option
        to deposit up to half of that 20% into an Eagle Invest account.
      </p>
      <p className="my-3">
        Eagle Invest is an investment advisory service offered by First Republic
        Investment Management, Inc., sub-advised by SigFig Wealth Management,
        LLC (SigFig). SigFig is an SEC-registered investment adviser and a
        subsidiary of Nvest, Inc. SigFig is not affiliated with First Republic
        Private Wealth Management or any of its affiliates, nor is it affiliated
        with Pershing, LLC. For more information, please see SigFig's privacy
        policy on their public website. $5,000 minimum investment required.
      </p>
      <p className="my-3">
        Investment Advisory Services are offered through First Republic
        Investment Management, an SEC-Registered Investment Advisor and a wholly
        owned subsidiary of First Republic Bank.
      </p>
      <div className="my-3 px-2 border-2 border-gray-300 max-w-fit">
        <text className="block font-semibold">
          Investment and Advisory Products and Services are Not FDIC Insured,
          Not Bank Guaranteed and May Lose Value.
        </text>
      </div>
      <p className="my-3">
        3 - Personal Line of Credit minimum is $70,000; maximum is the lesser of
        $350,000 or debt amount to be repaid at origination plus $100,000. If no
        debt to be repaid at origination, the maximum loan amount is $100,000.
        This product cannot be used to pay off credit card debt at origination.
        Please note only debts that appear on your consumer credit report or
        student loans are eligible to be repaid at origination. Line of credit
        cannot be fully drawn at origination.
      </p>
      <p className="my-3">
        Product is not available in all markets. For a complete list of
        locations, visit{" "}
        <a
          className="text-navyblue underline"
          target="_blank"
          rel="noreferrer"
          href="https://www.firstrepublic.com/locations"
        >
          firstrepublic.com/locations
        </a>{" "}
        . Applicants must meet a First Republic banker to open account. This is
        not a commitment to lend. Loan approval is subject to a completed
        application with associated documentation and confirmation that income,
        liquidity, credit history and other application information meet the
        minimum requirements for this product. Applicants should discuss line of
        credit terms, conditions and account details with their banker.
      </p>
      <p className="my-3">*Annual Percentage Rate (APR), with discounts.</p>
    </div>
  );
}
