import React from "react";

export function SFBannerTitle({
  title,
  titleSpan,
  titleSpanNewLine = false,
  bodyAlignment,
}) {
  return (
    <h1
      className={`font-semibold sm:text-xl md:text-3xl lg:text-6xl text-navyblue ${
        bodyAlignment === "right"
          ? "md:text-left sm:text-center"
          : "text-center"
      }`}
      style={{
        fontFamily: "Playfair Display",
        color: "#052461",
        textAlign: "center",
        fontSize: "66px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "130%",
        letterSpacing: "-0.33px",
      }}
    >
      {title}
      {titleSpanNewLine ? <br /> : <></>}
      <span className="font-normal italic">{titleSpan}</span>
    </h1>
  );
}

export function SFBannerSubTitle({ subTitle }) {
  return (
    <div className="text-gray-600 sm:text-sm md:text-md lg:text-lg font-jost place-items-center">
      {subTitle}
    </div>
  );
}

export function SFBannerImage({ imgSrc, imgSrcSm, alt, fixedHeight }) {
  return (
    <div className="sm:w-screen md:w-full place-self-end text-center">
      <img
        className={
          fixedHeight
            ? "md:block sm:hidden place-self-center mx-auto h-96"
            : "md:block sm:hidden md:w-full lg:h-[80vh] md:h-[60vh]"
        }
        src={imgSrc}
        alt={alt}
      />
      <img
        className="md:hidden sm:block sm:mx-auto banner-image"
        src={imgSrcSm}
        alt={alt}
      />
    </div>
  );
}

export function SFBannerAspiringParentsLg({ title, subTitle }) {
  return (
    <div className="lg:flex sm:hidden bg-homebanner bg-cover bg-center justify-center banner-height lg:h-screen">
      <div className="flex items-center w-10/12">
        <div className="flex flex-col-reverse grid gap-16 grid-cols-2">
          <div className="flex flex-col justify-center">
            <div className="text-lg font-jost text-gray-600">{title}</div>
            {subTitle}
          </div>
          <div className="flex justify-end">
            <img
              className="w-9/12 py-32 rounded-3xl"
              src="/assets/aspiringParentsLg.png"
              alt="aspiring_parents"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function SFBannerCoupleHoldingHandsLg({ title, subTitle }) {
  return (
    <div className="lg:flex sm:hidden bg-homebanner bg-cover bg-center justify-center banner-height lg:h-screen">
      <div className="flex items-center w-10/12">
        <div className="flex flex-col-reverse grid gap-16 grid-cols-2">
          <div className="flex flex-col justify-center">
            <div className="text-lg font-jost text-gray-600">{title}</div>
            {subTitle}
          </div>
          <div className="flex justify-end">
            <img
              className="w-9/12 py-32 rounded-3xl"
              src="/assets/coupleHoldingHandsLg.png"
              alt="couple_holding_hands"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function SFBannerWomenWithLaptopLg({ title, subTitle }) {
  return (
    <div className="lg:block sm:hidden bg-homebanner bg-cover bg-center banner-height lg:h-screen">
      <div className="flex items-center justify-center">
        <div className="flex items-center">
          <div className="grid gap-64 md:grid-cols-2">
            <div className="flex flex-col justify-center px-32">
              <div className="text-lg font-jost text-gray-600">{title}</div>
              {subTitle}
            </div>
            <div className="flex justify-end">
              <img
                className="w-9/12 py-32 rounded-3xl mx-auto"
                src="/assets/womenWithLaptopLg.png"
                alt="women_with_laptop"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function SFBanner({
  title,
  subTitle,
  bgImage,
  bodyAlignment,
  img,
}) {
  const rowComponentStyle = () => {
    if (bodyAlignment === "center") {
      return "sm:hidden md:block grid grid-cols-1 gap-y-5 place-self-center lg:w-screen";
    } else if (bodyAlignment === "right") {
      return "grid grid-cols-1 gap-y-5 place-self-center lg:w-11/12 md:w-7/12 lg:pl-24 md:p-8 md:flex md:flex-col sm:hidden";
    }
  };
  const rowImageStyle = () => {
    if (bodyAlignment === "right") {
      return "hidden";
    }
  };
  const colComponentStyle = () => {
    if (bodyAlignment === "center") {
      return "md:hidden sm:block grid grid-cols-1 gap-y-5 place-self-center md:w-full";
    } else if (bodyAlignment === "right") {
      return "grid grid-cols-1 gap-y-5 md:p-0 sm:p-8 md:w-full";
    }
  };
  return (
    <div
      className={`${bgImage} w-screen ${
        window.location.pathname.startsWith("/components")
          ? ""
          : "banner-height landscape:sm:h-fit landscape:lg:h-screen lg:h-screen"
      } flex flex-row`}
      style={{
        background: "linear-gradient(180deg, #DEF6FB 0%, #F9FEFF 66.15%)",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className={rowComponentStyle()}>
        <div>{title}</div>
        <div>{subTitle}</div>
        <div className={rowImageStyle()}>{img}</div>
      </div>
      <div className={colComponentStyle()}>
        <div className="md:hidden sm:block sm:w-full sm:place-self-start md:place-self-center">
          {title}
        </div>
        <div className="md:w-full sm:place-self-center md:place-self-end">
          {img}
        </div>
        <div className="md:hidden sm:block self-strech">{subTitle}</div>
      </div>
    </div>
  );
}
