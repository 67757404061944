import React from "react";
import { Link } from "react-router-dom";
import NavBarDropdown from "./NavBarDropdown";
import { setShowLoanEstimate } from "../../App/Features/AppSlice";
import Store from "../../App/Store";

export default function NavBarMenu({ user, showMenu }) {
  const linkStyle =
    "lg:bg-primary px-2 hover:opacity-30 lg:hover:text-black lg:inline-block sm:block";
  const buildAdminDropdown = () => {
    const options = [];
    if (user?.acl?.trackableLinks) {
      options.push({
        title: "Manage Trackable Links",
        to: "/trackable_link/manage",
      });
    }
    if (user?.acl?.blogs || user?.acl?.events) {
      options.push({
        title: "Manage Blogs & Events",
        to: "/blogs-events/manage",
      });
    }
    if (user?.acl?.partners) {
      options.push({ title: "Manage Partner Links", to: "/partners/manage" });
    }
    if (user?.acl?.affiliate) {
      options.push({
        title: "Manage Affiliate Links",
        to: "/affiliate/manage",
      });
    }
    if (user?.acl?.marketplace) {
      options.push({
        title: "Upload MoneyLion Funding CSV",
        to: "/even/funding-upload",
      });
      options.push({
        title: "Upload MoneyLion Leads CSV",
        to: "/even/leads-upload",
      });
    }
    if (user?.acl?.users) {
      options.push({
        title: "Admin",
        to: "/admin",
      });
    }
    if (options.length === 0) {
      return <></>;
    } else {
      return (
        <NavBarDropdown title={"Admin"} options={options} showMenu={showMenu} />
      );
    }
  };
  if (user === undefined) {
    return <></>;
  }
  return (
    <div className="flex lg:flex-row sm:flex-col sm:text-white lg:text-navyblue">
      <div className="lg:mx-auto">
        <NavBarDropdown
          title={"Our Offerings"}
          options={[
            { title: "Financing Options", to: "/financing-options" },
            { title: "Cost Calculator", to: "/cost-calculator" },
            { title: "Grants and Discounts", to: "/grants" },
            {
              title: "Loan Repayment Calculator",
              to: "/loan-repayment-calculator",
            },
          ]}
          showMenu={showMenu}
        />
        <Link
          to="/educational-resources"
          aria-label="parenthood-resources"
          onClick={showMenu}
          className={linkStyle}
        >
          Education
        </Link>
        <Link
          to="/lgbtqParenthood"
          aria-label="lgbtq-parenthood"
          onClick={showMenu}
          className={linkStyle}
        >
          LGBTQIA+ Parenthood
        </Link>
        <NavBarDropdown
          title={"About Us"}
          options={[
            { title: "Our Mission", to: "/mission" },
            { title: "Blogs & Events", to: "/blogs-events" },
          ]}
          showMenu={showMenu}
        />
        {buildAdminDropdown()}
      </div>
    </div>
  );
}
