import React, { useState, useEffect, useRef } from "react";
import {
  notifyEmailSupportRequest,
  postPartnerConsultationCallEngagement,
} from "../api";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function GetInTouch() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const scrollRef = useRef(null);
  const partner = useSelector((state) => state.partnerState.partner);
  const user = useSelector((state) => state.userState.user);

  useEffect(() => {
    if (location.pathname === "/newsletter") {
      scrollRef.current.scrollIntoView({
        block: "center",
      });
    }
  }, [location]);

  const onSubmit = (e) => {
    if (email.trim().length === 0) {
      setMessage("Please enter in an email address.");
      return;
    }
    setLoading(true);
    notifyEmailSupportRequest(email)
      .then(() => {
        setMessage(
          `${email} was successfully added to the Sunfish mailing list`,
        );
        setEmail("");
        setLoading(false);
      })
      .catch((_) => {
        setMessage(
          "Something went wrong, please reach out at support@joinsunfish.com",
        );
        setEmail("");
        setLoading(false);
      });
  };

  return (
    <div
      ref={scrollRef}
      className={`p-3 lg:p-20 py-12 lg:py-28 lg:bg-getintouch bg-getintouchphone bg-cover bg-center rounded-3xl my-24 w-10/12 md:w-8/12 ${
        location.pathname === "/warranty" ? "hidden" : "block"
      }`}
    >
      <div className="flex flex-col items-center">
        <h1 className="italic flex items-center flex-col literata-font lg:leading-tight text-3xl text-navyblue md:text-5xl text-center md:text-left">
          Sign up for
          <span className="font-jost font-semibold not-italic">
            our newsletter
          </span>
        </h1>
        <div className="lg:w-5/12 p-3 mt-5">
          <div className="flex items-center justify-between bg-white rounded-full">
            <input
              type="email"
              className="py-4 lg:py-5 pl-5 w-full lg:pl-8 bg-transparent outline-none"
              placeholder="Enter your email address"
              onChange={(e) => setEmail(e.target.value)}
              aria-label="email-input-get-in-touch"
              value={email}
            />
            <button
              disabled={loading}
              onClick={onSubmit}
              className={
                loading
                  ? "lg:block hidden ml-auto h-16 w-24 lg:w-32 bg-gray-500 text-white rounded-full"
                  : "lg:block hidden ml-auto h-16 w-24 lg:w-32 bg-navyblue text-white rounded-full"
              }
            >
              Submit
            </button>
            <button
              disabled={loading}
              onClick={onSubmit}
              className={
                loading
                  ? "lg:hidden ml-auto px-2 w-16 h-16 flex items-center justify-center bg-gray-500 rounded-full"
                  : "lg:hidden ml-auto px-2 w-16 h-16 flex items-center justify-center bg-navyblue rounded-full"
              }
              aria-label="get-in-touch"
            >
              <img src="/assets/arrow-white.png" alt="submit" />
            </button>
          </div>
        </div>
        {message ? <span>{message}</span> : null}
        <span className="text-navyblue my-10 font-jost text-4xl">or</span>
        <a
          className="lg:block hidden text-navyblue bg-yellow px-8 text-center py-4 font-semibold literata-font lg:leading-tight text-sm rounded-full"
          href="https://calendly.com/joinsunfish/financial-consultation"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            if (partner && user) {
              postPartnerConsultationCallEngagement(
                user.uid,
                user.email,
                partner.name,
              );
            }
          }}
        >
          Schedule a call with our team here
        </a>
        <a
          className="lg:hidden text-navyblue bg-yellow px-8 text-center py-4 font-semibold literata-font lg:leading-tight rounded-full"
          href="https://calendly.com/joinsunfish/financial-consultation"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            if (partner && user) {
              postPartnerConsultationCallEngagement(
                user.uid,
                user.email,
                partner.name,
              );
            }
          }}
        >
          Schedule a call
        </a>
      </div>
    </div>
  );
}
